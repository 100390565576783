<template>
  <td :class="outputClass">
    {{ display }}
  </td>
</template>

<script>
export default {
  name: "TdStatus",
  props: {
    success: { type: Boolean, default: null },
    fail: { type: Boolean, default: null },
    display: [Number, String],
    successClass: { type: String, default: "table-success" },
    failClass: { type: String, default: "table-danger" },
    unknownClass: { type: String, default: "table-secondary" },
  },
  computed: {
    outputClass() {
      const success = this.success !== null ? this.success : !this.fail;
      const fail = this.fail !== null ? this.fail : !this.success;

      if (this.display === null) return "";

      if (this.display !== "NaN") {
        // Success?
        if (success && !fail) return this.successClass;
        // Fail?
        if (fail && !success) return this.failClass;
      }

      // Unknown
      return this.unknownClass;
    },
  },
};
</script>
