<template>
  <div class="m-3">
    <sites-header>
      <!-- Navigation breadcrumbs -->
      <h4>
        <nav
          style="--bs-breadcrumb-divider: '&rsaquo;'"
          aria-label="breadcrumb"
        >
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'Sites' }" class="link-dark"
                >Sites</router-link
              >
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ siteId }}
            </li>
          </ol>
        </nav>
      </h4>
    </sites-header>

    <!-- Date controls -->
    <div class="row align-items-center justify-content-center" v-if="dates">
      <!-- Previous date button -->
      <div class="col-4 text-end">
        <button
          class="btn btn-outline-dark border-0"
          v-if="prevDate"
          @click="date = prevDate"
        >
          <i class="bi bi-chevron-left"></i>
          {{ longDateFormat(prevDate) }}
        </button>
      </div>

      <!-- Date calendar picker -->
      <div class="col-auto">
        <div class="flatpickr input-group mb-3 mt-3">
          <flat-pickr
            :config="config"
            v-model="date"
            placeholder="Select date..."
          ></flat-pickr>

          <button
            class="btn btn-primary"
            title="toggle"
            onclick="this.parentElement.querySelector('.form-control.input').click()"
          >
            <i v-if="!loadingItem" class="bi bi-calendar"></i>

            <div
              v-else
              class="spinner-border"
              style="width: 1rem; height: 1rem"
              role="status"
            ></div>
          </button>
        </div>
      </div>

      <!-- Next date button -->
      <div class="col-3">
        <button
          class="btn btn-outline-dark border-0"
          v-if="nextDate"
          @click="date = nextDate"
        >
          {{ longDateFormat(nextDate) }}
          <i class="bi bi-chevron-right"></i>
        </button>
      </div>
    </div>

    <!-- Data table -->
    <div class="mw-100 overflow-auto">
      <table class="table table-hover" v-if="item && item.DailySummary">
        <thead>
          <tr class="table-dark">
            <th>Path</th>
            <th>Perf Rating</th>
            <th>Combined MR</th>
            <th>Temp MR</th>

            <template v-for="c in item.DailySummary.EnabledConstituents">
              <th v-if="c !== 'CH4'" :key="c">{{ c }} MR</th>
            </template>

            <th>
              <info-text
                desc="The typical time between measurements, separating the fastest 50% times from the slowest 50%"
              >
                Measurement Rate Median (s)
              </info-text>
            </th>
            <th>
              <info-text
                desc="The generally slowest time between measurements, with only 5% of times exceeding this value"
              >
                Measurement Rate 95th Percentile (s)
              </info-text>
            </th>

            <template
              v-for="c in item.DailySummary.EnabledConstituents"
              :key="c"
            >
              <th v-if="c !== 'CH4'">Signal Strength Avg {{ c }}</th>
            </template>

            <th>Low Signal Events</th>
            <th>PitchX Range</th>
            <th>PitchY Range</th>
            <th>CatchX Range</th>
            <th>CatchY Range</th>
            <th>Pitch Avg Temp</th>
            <th>Pitch Min Temp</th>
            <th>Pitch Max Temp</th>
            <th>Catch Avg Temp</th>
            <th>Catch Min Temp</th>
            <th>Catch Max Temp</th>
            <th>Path Temperature Median (C)</th>
            <th>Path Temperature Min (C)</th>
            <th>Path Temperature Max (C)</th>

            <!-- Constituent Path Median/Min/Max -->
            <template
              v-for="c in item.DailySummary.EnabledConstituents"
              :key="c"
            >
              <template v-if="c === 'CO'">
                <th>Path {{ c }} Median (PPM)</th>
                <th>Path {{ c }} Min (PPM)</th>
                <th>Path {{ c }} Max (PPM)</th>
              </template>

              <template v-else>
                <th>Path {{ c }} Median (%)</th>
                <th>Path {{ c }} Min (%)</th>
                <th>Path {{ c }} Max (%)</th>
              </template>
            </template>

            <th>Rack Avg Temp</th>
            <th>Rack Min Temp</th>
            <th>Rack Max Temp</th>
            <th>
              <info-text
                desc="The typical time to complete the path sequence, separating the fastest 50% times from the slowest 50%"
              >
                Measurement Cycle Time Median (s)
              </info-text>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="pathNum in pathNums" :key="pathNum">
            <!-- Path -->
            <td>{{ pathNum }}</td>

            <!-- Perf Rating -->
            <td-status
              :success="monitors[pathNum].PerfRating < 3"
              :display="monitors[pathNum].PerfRating"
            />

            <!-- Combined MR -->
            <td-status
              :success="monitors[pathNum].CombinedMRResult < 5"
              :display="round(monitors[pathNum].CombinedMRResult, 2)"
            />

            <!-- Temp MR -->
            <td-status
              :success="monitors[pathNum].TempMRResult < 5"
              :display="round(monitors[pathNum].TempMRResult, 2)"
            />

            <!-- Constituent MRs -->
            <template
              v-for="c in item.DailySummary.EnabledConstituents"
              :key="c"
            >
              <td-status
                v-if="c !== 'CH4'"
                :success="monitors[pathNum].ConstituentMRResults[c] < 5"
                :display="round(monitors[pathNum].ConstituentMRResults[c], 2)"
              />
            </template>

            <!-- Measurement Rate Median (s) -->
            <!-- 
              Ideally, we would use a threshold of 3 seconds for PC and 10 for CB, but we will use
              [3, 10) as a gray area until the distinguishment between PC/CB gets added here 
            -->
            <td-status
              :success="monitors[pathNum].MeasurementTimeMedian_s < 3"
              :fail="monitors[pathNum].MeasurementTimeMedian_s >= 10"
              :display="round(monitors[pathNum].MeasurementTimeMedian_s, 3)"
            />

            <!-- Measurement Rate 95th Percentile (s) -->
            <td>
              {{ round(monitors[pathNum].MeasurementTime95Percentile_s, 3) }}
            </td>

            <!-- Constituent Signal Strength Avgs -->
            <template
              v-for="c in item.DailySummary.EnabledConstituents"
              :key="c"
            >
              <td-status
                v-if="c !== 'CH4'"
                :success="monitors[pathNum].SignalStrengthStats[c][0] > 40"
                :display="round(monitors[pathNum].SignalStrengthStats[c][0], 1)"
              />
            </template>

            <!-- Low Signal Events -->
            <td>
              {{ monitors[pathNum].LowSignalEventCount }}
            </td>

            <!-- PitchX Range -->
            <td-status
              :success="
                monitors[pathNum].MotorPositionRange_steps.Pitch[0] < 400
              "
              :display="monitors[pathNum].MotorPositionRange_steps.Pitch[0]"
            />

            <!-- PitchY Range -->
            <td-status
              :success="
                monitors[pathNum].MotorPositionRange_steps.Pitch[1] < 400
              "
              :display="monitors[pathNum].MotorPositionRange_steps.Pitch[1]"
            />

            <!-- CatchX Range -->
            <td-status
              :success="
                monitors[pathNum].MotorPositionRange_steps.Catch[0] < 400
              "
              :display="monitors[pathNum].MotorPositionRange_steps.Catch[0]"
            />

            <!-- CatchY Range -->
            <td-status
              :success="
                monitors[pathNum].MotorPositionRange_steps.Catch[1] < 400
              "
              :display="monitors[pathNum].MotorPositionRange_steps.Catch[1]"
            />

            <!-- Pitch Avg Temp -->
            <td-status
              :success="monitors[pathNum].PitchAvgT < 85"
              :display="round(monitors[pathNum].PitchAvgT, 1)"
            />

            <!-- Pitch Min Temp -->
            <td-status
              :success="monitors[pathNum].PitchMinT < 85"
              :display="round(monitors[pathNum].PitchMinT, 1)"
            />

            <!-- Pitch Max Temp -->
            <td-status
              :success="monitors[pathNum].PitchMaxT < 85"
              :display="round(monitors[pathNum].PitchMaxT, 1)"
            />

            <!-- Catch Avg Temp -->
            <td-status
              :success="monitors[pathNum].CatchAvgT < 85"
              :display="round(monitors[pathNum].CatchAvgT, 1)"
            />

            <!-- Catch Min Temp -->
            <td-status
              :success="monitors[pathNum].CatchMinT < 85"
              :display="round(monitors[pathNum].CatchMinT, 1)"
            />

            <!-- Catch Max Temp -->
            <td-status
              :success="monitors[pathNum].CatchMaxT < 85"
              :display="round(monitors[pathNum].CatchMaxT, 1)"
            />

            <!-- Path Temperature Median, Min, Max (C) -->
            <td>
              {{ round(monitors[pathNum].TemperatureMedian_C, 1) }}
            </td>
            <td>
              {{ round(monitors[pathNum].TemperatureMin_C, 1) }}
            </td>
            <td>
              {{ round(monitors[pathNum].TemperatureMax_C, 1) }}
            </td>

            <!-- Constituent Path Median, Min, Max -->
            <template
              v-for="c in item.DailySummary.EnabledConstituents"
              :key="c"
            >
              <!-- PPM -->
              <template v-if="c === 'CO'">
                <td>
                  {{
                    round(monitors[pathNum].ConcentrationMedian[c] * 1000000, 1)
                  }}
                </td>
                <td>
                  {{
                    round(monitors[pathNum].ConcentrationMin[c] * 1000000, 1)
                  }}
                </td>
                <td>
                  {{
                    round(monitors[pathNum].ConcentrationMax[c] * 1000000, 1)
                  }}
                </td>
              </template>

              <!-- % -->
              <template v-else>
                <td>
                  {{ round(monitors[pathNum].ConcentrationMedian[c] * 100, 4) }}
                </td>
                <td>
                  {{ round(monitors[pathNum].ConcentrationMin[c] * 100, 4) }}
                </td>
                <td>
                  {{ round(monitors[pathNum].ConcentrationMax[c] * 100, 4) }}
                </td>
              </template>
            </template>

            <!-- Rack Avg Temp -->
            <td-status
              :success="monitors[pathNum].RackAvgT < 40"
              :display="round(monitors[pathNum].RackAvgT, 1)"
            />

            <!-- Rack Min Temp -->
            <td-status
              :success="monitors[pathNum].RackMinT < 40"
              :display="round(monitors[pathNum].RackMinT, 1)"
            />

            <!-- Rack Max Temp -->
            <td-status
              :success="monitors[pathNum].RackMaxT < 40"
              :display="round(monitors[pathNum].RackMaxT, 1)"
            />

            <!-- Measurement Cycle Time Median (s) -->
            <td>
              {{ round(monitors[pathNum].MeasurementCycleTimeMedian_s, 3) }}
            </td>
          </tr>
        </tbody>
      </table>

      <div class="alert alert-danger text-center" role="alert" v-else-if="item">
        The system reported no measurements for this date.
      </div>
    </div>
  </div>
</template>

<style scoped>
@media only screen and (min-width: 768px) {
  /* If there is enough screen width, center the navigation breadcrumbs by offsetting it by the width of the logout button */
  ol.breadcrumb {
    margin-left: 95px;
    justify-content: center;
  }
}
</style>

<style>
.flatpickr-day:not(.flatpickr-disabled) {
  background: rgba(86, 159, 247, 0.25);
}

.flatpickr-day.selected {
  background: rgba(86, 159, 247, 1);
}

.flatpickr-day.dataless-date {
  background: rgba(220, 53, 69, 0.25);
}

.flatpickr-day.dataless-date.selected {
  background: rgba(220, 53, 69, 1);
  border-color: rgba(220, 53, 69, 1);
  color: white;
}
</style>

<script>
import store from "@/store";
import { round } from "@/helpers";

import SitesHeader from "@/components/SitesHeader.vue";
import TdStatus from "@/components/TdStatus.vue";
import InfoText from "@/components/InfoText.vue";

export default {
  name: "Site Data",
  components: { SitesHeader, TdStatus, InfoText },
  data() {
    InfoText;
    return {
      item: null,
      datalessDates: [],
      dates: null,
      siteId: null,
      date: null,
      loadingItem: false,
    };
  },
  created() {
    this.routeUpdated(this.$route);

    // Load available dates for site
    store
      .getItemDates(this.siteId)
      .then((dates) => {
        this.dates = dates.entryDates;
        this.datalessDates = dates.datalessDates;
      })
      .catch(() => this.backToHome());
  },
  beforeRouteUpdate(newRoute) {
    this.routeUpdated(newRoute);
  },
  methods: {
    routeUpdated(route) {
      this.siteId = route.params.siteId;
      this.date = route.params.date;

      this.loadingItem = true;

      // Load site data for date
      store
        .getItem(this.siteId, this.date)
        .then((item) => {
          this.loadingItem = false;
          this.item = item;
        })
        .catch(() => this.backToHome());
    },
    backToHome() {
      this.$router.replace({ name: "Sites" });
    },
    round,
    longDateFormat(isoDate) {
      // Exclude year from the output unless it's different from the selected date's year
      const date = new Date(isoDate);
      const selectedDate = new Date(this.date);

      if (selectedDate.getFullYear() === date.getUTCFullYear())
        return date.toLocaleString("default", {
          timeZone: "UTC",
          month: "long",
          day: "numeric",
        });

      return date.toLocaleString("default", {
        timeZone: "UTC",
        month: "long",
        day: "numeric",
        year: "numeric",
      });
    }
  },
  computed: {
    config() {
      return {
        enable: this.dates,
        altInput: true,
        minDate: this.dates[0],
        maxDate: this.dates[this.dates.length - 1],
        onDayCreate: (dObj, dStr, fp, dayElem) => {
          const datalessDates = this.datalessDates.map((x) => {
            const date = new Date(x);
            date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
            return date.getTime();
          });

          if (datalessDates.includes(dayElem.dateObj.getTime())) {
            dayElem.classList.add("dataless-date");
          }
        },
      };
    },
    dateIsValid() {
      return this.date && !isNaN(new Date(this.date));
    },
    pathNums() {
      return Object.keys(this.item.DailySummary.DailyMonitors).sort();
    },
    dateIndex() {
      return this.dates.indexOf(this.date);
    },
    prevDate() {
      return this.dates[this.dateIndex - 1];
    },
    nextDate() {
      return this.dates[this.dateIndex + 1];
    },
    monitors() {
      return this.item.DailySummary.DailyMonitors;
    },
  },
  watch: {
    siteId() {
      document.title = `${this.siteId} | ${this.date}`;
    },
    date() {
      // If there is a valid date, update the page title and route
      if (this.dateIsValid) {
        document.title = `${this.siteId} | ${this.date}`;

        this.$router.replace({
          name: "Site Data",
          params: {
            siteId: this.siteId,
            date: this.date,
          },
        });
      }
      // Otherwise, return to the overview page
      else this.backToHome();
    },
  },
};
</script>
