<template>
  <div class="mb-3">
    <button class="btn btn-secondary float-end" @click="logout">
      <i class="bi bi-box-arrow-right"></i>
      Logout
    </button>
    <slot></slot>
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "SitesHeader",
  methods: {
    logout() {
      delete localStorage.token;
      store.token = localStorage.token;

      // Go back to login page
      this.$router.replace({
        name: "Login",
      });
    },
  },
};
</script>
